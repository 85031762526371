<!--
 * @version: 1.0
 * @Date: 2023-04-20 18:02:42
 * @LastEditors: 杨慢慢
-->
<template>
  <div class="process">
    <div class="design" :style="'transform: scale(' + scale / 100 + ');'">
      <process-tree ref="process-tree" @selectedNode="nodeSelected" />
    </div>
    <div class="drawer">
      <a-drawer
        :title="null"
        v-model:visible="showConfig"
        :width="selectedNode.type === 'ROOT' || selectedNode.type === 'END' ? 356 : 444"
        placement="right"
        :closable="false"
        :maskStyle="{ opacity: '0', animation: 'none' }"
        :bodyStyle="{ padding: '0px' }"
        :wrapStyle="{ paddingTop: '60px' }"
      >
        <div class="node-config-content">
          <NodeConfig :headBgc="headBgc" @cancel="handleCancel" @ok="handleOk" :showConfig="showConfig" />
        </div>
      </a-drawer>
    </div>
  </div>
</template>

<script>
import ProcessTree from '@/views/flowable/process/ProcessTree.vue'
import NodeConfig from '@/views/flowable/common/process/config/NodeConfig.vue'
import DefaultProps from '@/views/flowable/process/DefaultNodeProps'

export default {
  name: 'flowPath',
  components: { ProcessTree, NodeConfig },
  data() {
    return {
      scale: 100,
      selected: {},
      showInput: false,
      showConfig: false,
      headBgc: ''
    }
  },
  computed: {
    selectedNode() {
      return this.$store.state.process.selectedNode
    },
    process() {
      return this.$store.state.process.design.process
    }
  },
  mounted() {
    // console.log('this.$store.state.process', this.$store.state.process)
  },
  methods: {
    validate() {
      return this.$refs['process-tree'].validateProcess()
    },
    nodeSelected(node) {
      console.log('配置节点', node)
      if (node.name === '默认条件') return
      this.showConfig = true
      // 背景色
      this.headBgc =
        this.selectedNode.type === 'ROOT'
          ? 'linear-gradient(90deg, #E9393F 0%, #ED9DA0 100%)'
          : this.selectedNode.type === 'APPROVAL'
          ? 'linear-gradient(90deg, #FF9535 0%, #FFC452 100%)'
          : this.selectedNode.type === 'CONDITION'
          ? 'linear-gradient(90deg, #60C7BC 0%, #00D3BE 100%)'
          : 'linear-gradient(90deg, #A5B1CC 0%, #D1D9E8 100%)'
    },

    // let initId = this.getRandomId()
    // 初始时点节点
    loadInitFrom() {
      this.$store.commit('loadForm', {
        formId: null,
        formName: '未命名表单',
        logo: {
          icon: 'el-icon-eleme',
          background: '#1e90ff'
        },
        settings: {
          commiter: [],
          admin: [],
          sign: false,
          notify: {
            types: ['APP'],
            title: '消息通知标题'
          }
        },
        groupId: undefined,
        formItems: this.$store.state.process.design.formItems,
        process: {
          id: 'root',
          parentId: 'root',
          type: 'ROOT',
          name: '发起',
          desc: '任何人',
          props: {
            cc: {
              assignedUser: []
            },
            ccType: false,
            formPerms: [],
            resource: false
          },
          children: {
            id: 'approval',
            parentId: 'root',
            type: 'APPROVAL',
            name: '审批人',
            desc: '审批人',
            props: this.$deepCopy(DefaultProps.APPROVAL_PROPS),
            children: {
              id: 'end',
              parentId: 'approval',
              type: 'END',
              name: '结束',
              desc: '结束',
              props: {
                cc: {
                  assignedUser: []
                },
                ccType: false,
                formPerms: [],
                resource: false
              },
              children: {}
            }
          }
        },
        remark: '备注说明'
      })
    },
    handleCancel(value) {
      // console.log('取消', value)
      this.showConfig = false
      this.$emit('cancel', false)
      console.log('this.$store.state.process', this.$store.state.process)
    },
    handleOk(value) {
      // console.log('确认', value)
      this.showConfig = value
      // console.log('this.$store.state.process', this.$store.state.process)
    },
    getRandomId() {
      return `node_${new Date()
        .getTime()
        .toString()
        .substring(5)}${Math.round(Math.random() * 9000 + 1000)}`
    }
  },
  created() {
    this.loadInitFrom()
    // console.log('this.$store.state.design',this.$store.state.design);
  },
  watch: {
    process: {
      deep: true,
      handler(node) {
        // console.log('监听流程设计变化',node);
      }
    }
  }
}
</script>

<style lang="less" scoped>
.design {
  width: 100%;
  margin: 0 auto;
  margin-top: 80px;
  display: flex;
  transform-origin: 50% 0px 0px;
}

.scale {
  z-index: 999;
  position: fixed;
  top: 80px;
  right: 40px;

  span {
    margin: 0 10px;
    font-size: 15px;
    color: #7a7a7a;
    width: 50px;
  }
}

.drawer {
  .node-config-content {
    padding: 0 0px 20px;
  }
}
</style>
